import * as yup from 'yup';

const please = "Un ou plusieurs champs manquants. 💡Veuillez renseigner "

let schema = yup.object().shape({
    sitedecliniaue: yup.string().email().required(please+'le site de la clinique'),
    dr: yup.string().required(please + 'le nom du clinicien'),
    emailpraticien: yup.string().email(please + 'le courriel du clinicien').required(please + 'le courriel du clinicien')
});

export default schema