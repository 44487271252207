import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import toast, { Toaster } from "react-hot-toast";
import ReactTooltip from "react-tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Consigne from "./Consigne";
import Header from "./Header";
import Success from "./Success";

import schema from "./schema";
import emailTheme from "./emailTheme";

import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import "./App.css";

const messages = {
  toast: {
    confirmation: "Votre demande a été envoyé",
  },
};

const BASE_API = "https://api.pathocmb.com/api/";

const App = () => {
  const [submited, setSubmited] = useState(false);
  const [formInput, setFormInput] = useState({
    adressea: [],
    sitedecliniaue: "cmb@specialistesmaxillo.com",
    datedemande: "",
    nom: "", //patient
    prenom: "",
    ddn: "",
    courriel: "",
    telephone1: "",
    telephone2: "",
    dr: "", //clinicien
    lieupratique: "",
    telephoneclinicien: "",
    rapportouinon: "",
    emailpraticien: "",
    lesionprecancereuse: "",
    sitelesionconcer: "",
    lesiondestissusmous: "",
    description: "",
    sitetm: "",
    couleur: "",
    douleur: "",
    lesionradiologique: "",
    descriptionlesion: "",
    brulurebuccale: "",
    secheressebuccale: "",
    nevralegiedutrijumeau: "",
    douleurpersistantesuivanttraitementendodontique: "",
    mauxdetetepersistants: "",
    douleurmusculaire: "",
    problemealatm: "",
    autresraisons: "",
    autresraisonsdescription: "",
    attachment1: "",
    attachment2: "",
    attachment3: "",
    attachment4: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
  };

  const handleSubmission = (e) => {
    const { name, files } = e.target;
    const formData = new FormData();

    formData.append("file", files[0]);
    console.log("formData", formData);

    fetch(BASE_API + "upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Success:", result);
        if (result.uri) {
          setFormInput({
            ...formInput,
            [name]: "https://api.pathocmb.com" + result.uri,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleFormSubmit = () => {
    // check validity
    schema
      .validate(formInput, { abortEarly: false })
      .then(function (valid) {
        if (valid) {
          fetch(BASE_API + "prelevements/agnosticNotify", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              to: formInput.sitedecliniaue + "," + formInput.emailpraticien,
              message: emailTheme(formInput),
            }),
          })
            .then((response) => response.json())
            .then((result) => {
              if (result && result.status) {
                console.log(result);
                toast.success(messages.toast.confirmation, {
                  duration: 8000,
                });
                setSubmited(true);
              }
            })
            .catch((e) => {
              toast.error("Un problème est survenu.", {
                duration: 8000,
              });
            });
        }
      })
      .catch((err) =>
        err.inner.forEach((e) => {
          toast.error(e.message, {
            duration: 8000,
          });
        })
      );
  };

  const handleCheckChange = (e) => {
    const { value, checked } = e.target;

    const adressea = !!checked
      ? [...formInput.adressea, value]
      : formInput.adressea.filter((a) => a !== value);

    setFormInput({ ...formInput, adressea });
  };

  return (
    <div className="App">
      <Header />
      <div style={{ marginBottom: "100px" }} />
      {!submited && (
        <>
          <h1>ADRESSÉE À</h1>
          <hr className="divider" />
          <div role="group" aria-labelledby="checkbox-group">
            <Checkbox
              type="checkbox"
              name="adressea"
              value="Dr Adel Kauzman"
              onChange={handleCheckChange}
            />
            <ReactTooltip />
            <span
              className={"tltp"}
              data-tip="Spécialiste en médecine buccale et pathologie buccale et maxillo-faciale (Ville Mont-Royal et Laval)"
            >
              Dr Adel Kauzman
            </span>
            <Checkbox
              type="checkbox"
              name="adressea"
              value="Dr Benoît Lalonde"
              onChange={handleCheckChange}
            />
            <ReactTooltip />
            <span
              className={"tltp"}
              data-tip="Spécialiste en médecine buccale (St-Hubert)"
            >
              Dr Benoît Lalonde
            </span>
            <Checkbox
              type="checkbox"
              name="adressea"
              value="Dre Gisèle Mainville"
              onChange={handleCheckChange}
            />
            <ReactTooltip />
            <span
              className={"tltp"}
              data-tip="Spécialiste en pathologie buccale et maxillo-faciale (St-Hubert)"
            >
              Dre Gisèle Mainville
            </span>
            <Checkbox
              type="checkbox"
              name="adressea"
              value="Dre Nathalie Rei"
              onChange={handleCheckChange}
            />
            <ReactTooltip />
            <span
              className={"tltp"}
              data-tip="Certificat en médecine buccale(Ville Mont-Royal, Laval et St-Hubert)"
            >
              Dre Nathalie Rei
            </span>
            <Checkbox
              type="checkbox"
              name="adressea"
              value="Dre Caroline Bissonnette"
              onChange={handleCheckChange}
            />
            <ReactTooltip />
            <span
              className={"tltp"}
              data-tip="Spécialiste en médecine buccale et pathologie buccale (Ville Mont-Royal)"
            >
              Dre Caroline Bissonnette
            </span>
            <Checkbox 
              type="checkbox"
              name="adressea"
              value="Dr Vincent Lavoie"
              onChange={handleCheckChange}
            />
            <ReactTooltip />
            <span
              className={"tltp"}
              data-tip="Maîtrise en médecine buccale et pathologie buccale et maxillofaciale (Ville Mont-Royal, Westmount et St-Hubert)" // Info-bulle pour Dr Lavoie
            >
              Dr Vincent Lavoie
            </span>
          </div>

          <FormControl variant="filled" fullWidth>
            <h3>Site de clinique</h3>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.sitedecliniaue}
              label="Site de clinique"
              onChange={(v) =>
                setFormInput({ ...formInput, sitedecliniaue: v.target.value })
              }
            >
              <MenuItem value="cmb@specialistesmaxillo.com" selected="selected">
              Ville Mont-Royal (Clinique des Spécialistes Maxillo)
      </MenuItem>
      <MenuItem value="info@maxillovendome.ca">Westmount (Clinique MaxilloVendôme)</MenuItem>
      <MenuItem value="info@prosthodontielaval.com">Laval</MenuItem>
      <MenuItem value="medbuc@specialistes-dentaires.ca">
        Saint-Hubert
      </MenuItem>
            </Select>
            <h3>Date de la demande</h3>
            <TextField
              name="datedemande"
              id="datedemande"
              variant="outlined"
              type={"date"}
              onChange={handleChange}
            />
          </FormControl>

          {/**
           *IDENTIFICATION DU PATIENT
           */}

          <h1>IDENTIFICATION DU PATIENT</h1>
          <hr className="divider" />
          <FormControl variant="filled" fullWidth>
            <div>
              <h3>Nom</h3>
              <TextField
                fullWidth
                name="nom"
                id="nom"
                variant="outlined"
                onChange={handleChange}
              />
            </div>

            <div>
              <h3>Prénom</h3>
              <TextField
                fullWidth
                name="prenom"
                id="prenom"
                variant="outlined"
                onChange={handleChange}
              />
            </div>
            <div>
              <h3>Date de naissance</h3>
              <TextField
                fullWidth
                name="ddn"
                id="ddn"
                variant="outlined"
                type={"date"}
                onChange={handleChange}
              />
            </div>
            <div>
              <h3>Courriel</h3>
              <TextField
                fullWidth
                name="courriel"
                id="courriel"
                type="email"
                variant="outlined"
                onChange={handleChange}
              />
            </div>
            <div>
              <h3>Téléphone 1</h3>
              <TextField
                fullWidth
                name="telephone1"
                id="telephone1"
                variant="outlined"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <h3>Téléphone 2</h3>
              <TextField
                fullWidth
                name="telephone2"
                id="telephone2"
                variant="outlined"
                onChange={handleChange}
              />
            </div>
          </FormControl>

          {/**
           *IDENTIFICATION DU Clinicien
           */}

          <h1>IDENTIFICATION DU CLINICIEN</h1>
          <hr className="divider" />
          <FormControl variant="filled" fullWidth>
            <h3>Votre nom complet</h3>
            <TextField
              required
              fullWidth
              name="dr"
              id="dr"
              placeholder="Dr."
              variant="outlined"
              onChange={handleChange}
            />
            <h3>Lieu de pratique(ville)</h3>
            <TextField
              fullWidth
              name="lieupratique"
              id="lieupratique"
              variant="outlined"
              onChange={handleChange}
            />

            <h3>Téléphone</h3>
            <TextField
              required
              fullWidth
              name="telephoneclinicien"
              id="telephoneclinicien"
              variant="outlined"
              onChange={handleChange}
            />

            <h3>Désirez-vous un rapport par courrier électronique?</h3>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="rapportouinon"
              value={formInput.rapportouinon}
              onChange={(v) =>
                setFormInput({ ...formInput, rapportouinon: v.target.value })
              }
            >
              <MenuItem value="oui">Oui</MenuItem>

              <MenuItem value="non" selected="selected">
                Non
              </MenuItem>
            </Select>

            <h3>Si oui, SVP indiquez votre adresse courriel</h3>
            <TextField
              fullWidth
              name="emailpraticien"
              id="emailpraticien"
              variant="outlined"
              onChange={handleChange}
            />
          </FormControl>

          {/**
           *RAISON DE LA CONSULTATION
           */}

          <h1>RAISON DE LA CONSULTATION</h1>
          <hr className="divider" />

          <div style={{ display: "inline" }}>
            <Checkbox
              type="checkbox"
              name="lesionprecancereuse"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    lesionprecancereuse: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    lesionprecancereuse: "",
                  });
                }
              }}
            />
            Lésion précancéreuse/cancéreuse
          </div>

          <TextField
            style={{ paddingBottom: "20px" }}
            fullWidth
            placeholder="site:"
            name="sitelesionconcer"
            id="sitelesionconcer"
            variant="outlined"
            onChange={handleChange}
          />

          <div style={{ display: "inline" }}>
            <Checkbox
              type="checkbox"
              name="lesiondestissusmous"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    lesiondestissusmous: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    lesiondestissusmous: "",
                  });
                }
              }}
            />
            Lésion des tissus mous
          </div>

          <TextField
            fullWidth
            placeholder="Description"
            name="description"
            id="description"
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            fullWidth
            placeholder="Site:"
            name="sitetm"
            id="sitetm"
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            fullWidth
            placeholder="Couleur:"
            name="couleur"
            id="couleur"
            variant="outlined"
            onChange={handleChange}
          />

          <h3>Douleur</h3>
          <Select
            fullWidth
            id="douleur"
            value={formInput.douleur}
            onChange={(v) =>
              setFormInput({ ...formInput, douleur: v.target.value })
            }
          >
            <MenuItem value="oui">Oui</MenuItem>

            <MenuItem value="non" selected="selected">
              Non
            </MenuItem>
          </Select>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="lesionradiologique"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    lesionradiologique: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    lesionradiologique: "",
                  });
                }
              }}
            />
            Lésion radiologique
          </div>

          <TextField
            fullWidth
            placeholder="Description:"
            name="descriptionlesion"
            id="descriptionlesion"
            variant="outlined"
            onChange={handleChange}
          />

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="brulurebuccale"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    brulurebuccale: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    brulurebuccale: "",
                  });
                }
              }}
            />
            Brûlure buccale persistante
          </div>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="secheressebuccale"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    secheressebuccale: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    secheressebuccale: "",
                  });
                }
              }}
            />
            Sécheresse buccale
          </div>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="nevralegiedutrijumeau"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    nevralegiedutrijumeau: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    nevralegiedutrijumeau: "",
                  });
                }
              }}
            />
            Névralgie du trijumeau
          </div>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="douleurpersistantesuivanttraitementendodontique"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    douleurpersistantesuivanttraitementendodontique: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    douleurpersistantesuivanttraitementendodontique: "",
                  });
                }
              }}
            />
            Douleur persistante suivant un traitement endodontique ou
            chirurgical
          </div>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="mauxdetetepersistants"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    mauxdetetepersistants: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    mauxdetetepersistants: "",
                  });
                }
              }}
            />
            Maux de tête persistants
          </div>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="brulurebuccale"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    brulurebuccale: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    brulurebuccale: "",
                  });
                }
              }}
            />
            Brûlure buccale persistante
          </div>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="douleurmusculaire"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    douleurmusculaire: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    douleurmusculaire: "",
                  });
                }
              }}
            />
            Douleur musculaire
          </div>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="problemealatm"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    problemealatm: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    problemealatm: "",
                  });
                }
              }}
            />
            Problème à l’ATM
          </div>

          <div style={{ display: "inline", marginTop: "20px" }}>
            <Checkbox
              type="checkbox"
              name="autresraisons"
              value="oui"
              onChange={(e) => {
                const { value, checked } = e.target;
                if (!!checked) {
                  setFormInput({
                    ...formInput,
                    autresraisons: value,
                  });
                } else {
                  setFormInput({
                    ...formInput,
                    autresraisons: "",
                  });
                }
              }}
            />
            Autre raison
          </div>

          <TextField
            multiline
            fullWidth
            placeholder="Veuillez indiquer les autres raisons"
            name="autresraisonsdescription"
            id="autresraisonsdescription"
            variant="outlined"
            onChange={handleChange}
          />

          <h1>PHOTOS/RADIOGRAPHIES</h1>
          <hr className="divider" />

          <TextField
            fullWidth
            type="file"
            name="attachment1"
            id="attachment1"
            variant="outlined"
            onChange={handleSubmission}
          />
          <TextField
            fullWidth
            type="file"
            name="attachment2"
            id="attachment2"
            variant="outlined"
            onChange={handleSubmission}
          />
          <TextField
            fullWidth
            type="file"
            name="attachment3"
            id="attachment3"
            variant="outlined"
            onChange={handleSubmission}
          />

          <Consigne />
          {
            <div
              className="SendBtn"
              style={{
                cursor: formInput.dr ? "pointer" : "not-allowed",
                backgroundColor: formInput.dr ? "#00bcd4" : "grey",
              }}
              onClick={handleFormSubmit}
            >
              ENVOYER
            </div>
          }
          <div style={{ paddingBottom: "150px" }} />
        </>
      )}

      <div style={{ paddingBottom: "20px" }} />
      {submited && (
        <>
          {" "}
          <Success /> <Consigne /> <div style={{ marginBottom: "120px" }} />
        </>
      )}

      <Toaster />
    </div>
  );
};

export default App;
