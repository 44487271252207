import logo from './logo.jpg'
const Header = () => (
    <div style={{
        position: 'absolute',
        height: '100px',
        top: 0,
        right: 0,
        width: '100%',
        display: 'flex',
        marginLeft:'100px',
        justifyContent: 'space-between',
        paddingRight: '10px',
        justifyItems: 'center',
        backgroundColor:'#5abad2'
    }}>
        <img src={logo} alt="logo" style={{ height: '100px', width: 'auto', marginLeft:'10px' }} />
        <div style={{marginBottom:'-15px'}}>
        <h5 style={{fontSize:'1.2em', color:'white', fontWeight:100}}>Demande de consultation</h5>
        </div>
        

    </div>
)

export default Header