const Consigne = () => (
    <div style={{
        textAlign: "left",
        backgroundColor: "#f5f5f5",
        padding: '10px',
        marginTop: '10px',
        borderRadius:'10px'
    }}>
        <h6 style={{margin:0}}>
        Consignes importantes pour le référant :

</h6>
        <ul style={{fontSize:'12px'}}>
            <li>La demande sera acheminée automatiquement à la clinique de votre choix (cochée ci-haut). Par la suite, les patients seront contactés <strong>sauf pour la clinique de St-Hubert</strong> où votre patient devra communiquer avec nous au 450 443-8659 pour prendre rendez-vous.</li>
            <li>Pour les problèmes d’ATM et pour les lésions radiologiques, veuillez nous faire parvenir une copie (électronique ou sur film) des radiographies pertinentes.</li>
            <li>Demander au patient d’avoir au moment de la consultation une liste récente de ses médicaments (via sa pharmacie).</li>
</ul>
    </div>
)

export default Consigne