const emailTheme = ({
    adressea ,
    datedemande ,
    nom , //patient
    prenom ,
    ddn ,
    courriel,
    sitedecliniaue,
    telephone1 ,
    telephone2 ,
    dr , //clinicien
    lieupratique ,
    telephoneclinicien ,
    rapportouinon ,
    emailpraticien ,
    lesionprecancereuse ,
    sitelesionconcer ,
    lesiondestissusmous ,
    description ,
    sitetm ,
    couleur ,
    douleur ,
    lesionradiologique ,
    descriptionlesion ,
    brulurebuccale ,
    secheressebuccale ,
    nevralegiedutrijumeau ,
    douleurpersistantesuivanttraitementendodontique ,
    mauxdetetepersistants ,
    douleurmusculaire ,
    problemealatm ,
    autresraisonsdescription ,
    attachment1 ,
    attachment2 ,
    attachment3 ,
    attachment4 ,
}) => {
    return `
    
    <html>
<head>
<title>DEMANDE EN LIGNE</title>
</head>
<body>

<table style="width: 763px;">
<tbody>
<tr>
<td style="width: 424px;" valign="top">
<p><strong>Date de la demande</strong>: ${datedemande}</p>
<p><strong>Site de clinique</strong>: ${sitedecliniaue}</p>
<p><strong>Adress&eacute; &agrave;</strong>: ${adressea.join(',')}</p>
<p>&nbsp;</p>
<h3>IDENTIFICATION DU CLINICIEN</h3>
<p><strong>Dr</strong>: ${dr}</p>
<p><strong>Lieu de pratique</strong>: ${lieupratique}</p>
<p><strong>T&eacute;l&eacute;phone</strong>: ${telephoneclinicien}</p>
<p><strong>D&eacute;sirez-vous un rapport par courriel</strong>: ${rapportouinon}</p>
<p><strong>Courriel</strong>: ${emailpraticien}</p>
<p>&nbsp;</p>
<h3>IDENTIFICATION DU PATIENT</h3>
<p><strong>Nom</strong>: ${nom}</p>
<p><strong>Pr&eacute;nom</strong>: ${prenom}</p>
<p><strong>DDN</strong>: ${ddn}</p>
<p><strong>Courriel</strong>: ${courriel}</p>
<p><strong>T&eacute;l&eacute;phone 1</strong>: ${telephone1}</p>
<p><strong>T&eacute;l&eacute;phone 2</strong>: ${telephone2}</p>
</td>
<td style="width: 329px;" valign="top">
<h3>RAISON DE LA CONSULTATION</h3>
<p><strong>L&eacute;sion pr&eacute;canc&eacute;reuse/canc&eacute;reuse</strong>: ${lesionprecancereuse}</p>
<p><strong>Site</strong>: ${sitelesionconcer}</p>
<p><strong>L&eacute;sion des tissus mous</strong>: ${lesiondestissusmous}</p>
<p><strong>Description</strong>: ${description}</p>
<p><strong>Site</strong>: ${sitetm}</p>
<p><strong>Couleur</strong>: ${couleur}</p>
<p><strong>Douleur</strong>: ${douleur}</p>
<p><strong>L&eacute;sion radiologique</strong>: ${lesionradiologique}</p>
<p><strong>Description</strong>: ${descriptionlesion}</p>
<p><strong>Br&ucirc;lure buccale persistante</strong>: ${brulurebuccale}</p>
<p><strong>S&eacute;cheresse buccale</strong>: ${secheressebuccale}</p>
<p><strong>N&eacute;vralgie du trijumeau</strong>: ${nevralegiedutrijumeau}</p>
<p><strong>Douleur persistante suivant un traitement endodontique ou chirurgical</strong>: ${douleurpersistantesuivanttraitementendodontique}</p>
<p><strong>Maux de t&ecirc;te persistants</strong>: ${mauxdetetepersistants}</p>
<p><strong>Douleur musculaire</strong>: ${douleurmusculaire}</p>
<p><strong>Probl&egrave;me &agrave; l&rsquo;ATM</strong>: ${problemealatm}</p>
<p><strong>Autre raison</strong>: ${autresraisonsdescription}</p>
<p><strong>Photos/radiographies</strong>:
<a href="${attachment1}">${attachment1}</a ><br /><a href="${attachment2}">
${attachment2}</a><br /><a href="${attachment3}">${attachment3}</a><br /><a href="${ attachment4}">${attachment4}</a></p >
</td>
</tr>
<tr>
<img src="${attachment1}" width="200" height="200" style="object-fit: cover"  >
<img src="${attachment2}" width="200" height="200" style="object-fit: cover" >
<img src="${attachment3}" width="200" height="200" style="object-fit: cover" >
<img src="${attachment4}" width="200" height="200" style="object-fit: cover" >
</tr>
</tbody>
</table>
</body>
</html>
`
}

export default emailTheme