import Button from "@material-ui/core/Button";

const Success = () => (
  <div style={{ padding: "50px", textAlign: "center" }}>
    <p>
      Veuillez noter que les délais pour avoir un appel de la clinique et un RV
      en médecine buccale peuvent être longs. SVP, aviser votre patient(e). En
      cas d’urgence (cas de cancer) veuillez communiquer directement avec la
      clinique de votre choix (Laval, Mont-Royal, Rive-Sud).
    </p>
    <Button variant="outlined" onClick={() => window.location.reload()}>
      Envoyer une nouvelle demande
    </Button>

    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
        marginTop: "50px",
      }}
    >
      <div>
        <ContactCard
          title="Saleh, Robin & Associés S.E.N.C.R.L."
          address="750 chemin Lucerne, bureau 100
                Ville Mont-Royal (Québec) H3R 2H6"
          phones={[
            {
              name: "Téléphone",
              value: "514-389-4400",
            },
            { name: "Télécopieur", value: " 514 389-4134" },
            { name: "Courriel", value: "cmb@specialistesmaxillo.com" },
          ]}
        />
      </div>
      <div>
      <ContactCard
          title="Spécialistes Dentaires Rive-Sud"
          address="3124 boul. Moïse-Vincent St-Hubert (Quebec)
          J3Z 0C4"
          phones={[
            {
              name: "Téléphone",
              value: "450 443-8659",
            },
            { name: "Télécopieur", value: " 450 445-8255" },
            { name: "Courriel", value: "medbuc@specialistes-dentaires.ca" },
          ]}
        />
      </div>
      <div>
      <ContactCard
          title="Prosthodontie Laval"
          address="1555 boul. de l’Avenir, suite 300 Laval (Québec) H7S 2N5"
          phones={[
            {
              name: "Téléphone",
              value: "450 933-2022",
            },
            { name: "Télécopieur", value: "450 933-2586" },
            { name: "Courriel", value: "info@prosthodontielaval.com" },
          ]}
        />
      </div>
    </div>
  </div>
);

export default Success;

const ContactCard = ({ title, address, phones }) => (
  <div className="contactCard">
    <p style={{margin:0, fontWeight:900}}>{title}</p>
    <p>{address}</p>
    {phones && (
      <ul>
        {phones.map((p, k) => (
          <li key={k + 9}>{p.name + ": " + p.value}</li>
        ))}
      </ul>
    )}
  </div>
);
